.resume {
  border-radius: 25px;
  padding: 2em 2em 0em 2em;
  margin: 0 auto;
  width: 95%;
  text-align: left;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear, background 0.5s;
  position: relative;
  background: transparent;
  border: 1px solid transparent;
  overflow: hidden;
  z-index: 1;
}

.resume::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: var(--clr-glass);
  z-index: -1;
  border-radius: 25px;
  opacity: 0.1; /* Reduced opacity for a more transparent look */
}

.resume:hover {
  box-shadow: var(--shadowmore);
  border: 1px solid rgba(165, 165, 165, 0.09);
}

.resume:hover::before {
  --clr-glass: var(--clr-glass-hover);
  opacity: 0.2; /* Increased opacity on hover */
}


.resume::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  pointer-events: none;
  opacity: 0;

}

.resume:hover::after {
  opacity: 0.1;
}

.resume__description {
  margin-top: 1em;
}

.resume__skills {
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  margin: 1.2em 0;
  position: relative;
}

.resume__skills-item {
  margin: 0.5em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.resume .link--icon {
  margin-left: 0.5em;
}


.resume-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.role-and-company {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.resume-role {
  font-size: 1.4em;
}

.resume-company {
  font-size: 1.1em;
  padding: 0.2em 0em 1em 0em;
}

.resume-dates {
  font-size: 0.9em;
  text-align: right;
}
.resume-description {
  font-size: 0.85em;
  white-space: pre-wrap; /* or 'pre' */
}

.resume-name {
  text-align: left;
}

.resume .link--icon {
  margin-left: 0.5em;
  position: absolute;
  bottom: -1em;
  right: 0;
  margin: 1.2em;
}

.resume-image-container {
  display: block; /* Show the container on desktop */
  position: absolute;
  max-height: 6em;
  filter: var(--image-filter);
  right: 1em; /* Align to the right */
  bottom: 1em;
  background-size: cover;
  opacity: 0.2; /* Adjust for desired transparency */
  z-index: -1; /* Ensures the image is behind the text */
}
