.skills__list {
  max-width: 450px;
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills__list-item {
  margin: 0.2em;
  padding: 0em 0.3em; /* Increased padding for better visibility */
  font-weight: 500;
  font-size: 0.8rem;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px); /* For Safari */
  box-shadow: 0 1px 2px 0 rgba(0, 77, 64, 0.2); /* Shadow for depth */
  background-color: var(--itemcolour);
  border: 1px solid var(--shadowmore);
  color: var(--textcolour);
}
